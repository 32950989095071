<template>
  <v-container class="ma-0 pa-0" fill-height fluid>
    <v-card
      elevation="0"
      height="100%"
      width="100%"
      class="fill-height"
      :class="{
        'pa-0 grey lighten-4': backgroundColor && $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-row v-if="showLogo" justify="center">
        <v-col cols="12" class="d-flex flex-column">
          <img
            width="160"
            class="py-10 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5"
            src="@/assets/images/logo.png"
            alt="Dig Logix Logo"
          />
        </v-col>
      </v-row>

      <v-card
        :max-width="$vuetify.breakpoint.mdAndUp ? '50%' : '80%'"
        class="ma-auto pb-8 pt-16 rounded-lg"
        elevation="0"
      >
        <v-row justify="center" class="mb-4">
          <svg
            v-if="isWelcomeMessage"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M53.6602 23.6604C57.1616 36.7276 49.4069 50.1591 36.3397 53.6604C23.2725 57.1618 9.84109 49.4071 6.33974 36.3399C2.8384 23.2727 10.5931 9.84129 23.6602 6.33994C36.7274 2.8386 50.1589 10.5933 53.6602 23.6604ZM8.6604 35.7181C11.8183 47.5037 23.9324 54.4977 35.7179 51.3398C47.5035 48.1819 54.4975 36.0678 51.3396 24.2823C48.1817 12.4967 36.0676 5.50267 24.2821 8.6606C12.4965 11.8185 5.50247 23.9326 8.6604 35.7181Z"
              fill="#1E853A"
            />
            <path
              d="M18.7968 30.8704L25.3912 37.5198L40.4453 22.5723"
              stroke="#1E853A"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <img
            v-else
            src="./../../assets/images/email.png"
            role="img"
            aria-labelledby="messageHeaderLabel"
          />
        </v-row>
        <div
          id="messageHeaderLabel"
          class="d-flex justify-center transition-swing font-weight-bold text-h5 mb-4"
        >
          {{ text.headerLabel }}
        </div>
        <v-card elevation="0" max-width="300" class="ma-auto text-center"
          ><span
            class="body-2 text-center"
            v-html="(text.bodyLabel)"
          ></span>
        </v-card>
        <v-row class="justify-space-around mt-8 mb-8 mx-auto">
          <v-col
            v-if="isWelcomeMessage"
            cols="12"
            sm="10"
            lg="4"
            md="4"
            class="mx-auto"
          >
            <v-btn
              :outlined="button.buttonOutlined"
              :color="button.buttonColor"
              block
              class="text-none body-2 rounded-lg"
              :class="{ 'white--text': !button.buttonOutlined }"
              @click="emitEvent"
              >{{ $t(button.buttonLabel) }}</v-btn
            >
          </v-col>

          <v-btn
            v-else
            :outlined="button.buttonOutlined"
            :color="button.buttonColor"
            class="text-none body-2 rounded-lg"
            :class="{ 'white--text': !button.buttonOutlined }"
            @click="emitEvent"
            >{{ $t(button.buttonLabel) }}</v-btn
          >
        </v-row>
        <v-row v-if="hrLabel" justify="center" class="mx-8" lg="2" sm="12">
          <v-col cols="12" lg="8" sm="12">
            <v-row class="mx-8">
              <v-divider class="mr-4 my-auto"></v-divider>{{ $t(hrLabel)
              }}<v-divider class="ml-4 my-auto"></v-divider>
            </v-row>
          </v-col>
        </v-row>
        <router-link
          v-if="footerLink.footerLinkLabel"
          class="d-flex justify-center transition-swing body-1 mb-8 mt-8 text-decoration-none primary--text"
          :to="footerLink.footerLinkPath"
        >
          {{ $t(footerLink.footerLinkLabel) }}
        </router-link>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ComfirmationMessage",
  props: {
    showLogo: {
      type: Boolean,
      default: () => true,
    },
    isWelcomeMessage: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: Object,
      default: () => {
        return {
          headerLabel: "",
          bodyLabel: "",
          bodyLabelParams: {},
        };
      },
    },
    button: {
      type: Object,
      default: () => {
        return {
          buttonColor: "#0073D1",
          buttonLabel: "",
          buttonEvent: "",
          buttonOutlined: false,
        };
      },
    },
    hrLabel: {
      type: String,
      default: () => "",
    },
    footerLink: {
      type: Object,
      default: () => {
        return {
          footerLinkLabel: "",
          footerLinkPath: "",
        };
      },
    },
    backgroundColor: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    emitEvent() {
      this.$emit(this.button.buttonEvent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
