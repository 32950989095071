var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "fill-height",
          class: {
            "pa-0 grey lighten-4":
              _vm.backgroundColor && _vm.$vuetify.breakpoint.mdAndUp,
          },
          attrs: { elevation: "0", height: "100%", width: "100%" },
        },
        [
          _vm.showLogo
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("img", {
                        staticClass:
                          "py-10 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5",
                        attrs: {
                          width: "160",
                          src: require("@/assets/images/logo.png"),
                          alt: "Dig Logix Logo",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            {
              staticClass: "ma-auto pb-8 pt-16 rounded-lg",
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.mdAndUp ? "50%" : "80%",
                elevation: "0",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "mb-4", attrs: { justify: "center" } },
                [
                  _vm.isWelcomeMessage
                    ? _c(
                        "svg",
                        {
                          attrs: {
                            width: "60",
                            height: "60",
                            viewBox: "0 0 60 60",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M53.6602 23.6604C57.1616 36.7276 49.4069 50.1591 36.3397 53.6604C23.2725 57.1618 9.84109 49.4071 6.33974 36.3399C2.8384 23.2727 10.5931 9.84129 23.6602 6.33994C36.7274 2.8386 50.1589 10.5933 53.6602 23.6604ZM8.6604 35.7181C11.8183 47.5037 23.9324 54.4977 35.7179 51.3398C47.5035 48.1819 54.4975 36.0678 51.3396 24.2823C48.1817 12.4967 36.0676 5.50267 24.2821 8.6606C12.4965 11.8185 5.50247 23.9326 8.6604 35.7181Z",
                              fill: "#1E853A",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.7968 30.8704L25.3912 37.5198L40.4453 22.5723",
                              stroke: "#1E853A",
                              "stroke-width": "3",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          }),
                        ]
                      )
                    : _c("img", {
                        attrs: {
                          src: require("./../../assets/images/email.png"),
                          role: "img",
                          "aria-labelledby": "messageHeaderLabel",
                        },
                      }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center transition-swing font-weight-bold text-h5 mb-4",
                  attrs: { id: "messageHeaderLabel" },
                },
                [_vm._v(" " + _vm._s(_vm.text.headerLabel) + " ")]
              ),
              _c(
                "v-card",
                {
                  staticClass: "ma-auto text-center",
                  attrs: { elevation: "0", "max-width": "300" },
                },
                [
                  _c("span", {
                    staticClass: "body-2 text-center",
                    domProps: { innerHTML: _vm._s(_vm.text.bodyLabel) },
                  }),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "justify-space-around mt-8 mb-8 mx-auto" },
                [
                  _vm.isWelcomeMessage
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mx-auto",
                          attrs: { cols: "12", sm: "10", lg: "4", md: "4" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none body-2 rounded-lg",
                              class: {
                                "white--text": !_vm.button.buttonOutlined,
                              },
                              attrs: {
                                outlined: _vm.button.buttonOutlined,
                                color: _vm.button.buttonColor,
                                block: "",
                              },
                              on: { click: _vm.emitEvent },
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.button.buttonLabel)))]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "text-none body-2 rounded-lg",
                          class: { "white--text": !_vm.button.buttonOutlined },
                          attrs: {
                            outlined: _vm.button.buttonOutlined,
                            color: _vm.button.buttonColor,
                          },
                          on: { click: _vm.emitEvent },
                        },
                        [_vm._v(_vm._s(_vm.$t(_vm.button.buttonLabel)))]
                      ),
                ],
                1
              ),
              _vm.hrLabel
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-8",
                      attrs: { justify: "center", lg: "2", sm: "12" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "8", sm: "12" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-8" },
                            [
                              _c("v-divider", { staticClass: "mr-4 my-auto" }),
                              _vm._v(_vm._s(_vm.$t(_vm.hrLabel))),
                              _c("v-divider", { staticClass: "ml-4 my-auto" }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.footerLink.footerLinkLabel
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "d-flex justify-center transition-swing body-1 mb-8 mt-8 text-decoration-none primary--text",
                      attrs: { to: _vm.footerLink.footerLinkPath },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(_vm.footerLink.footerLinkLabel)) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }